<template>
  <div>
    <v-card outlined style="border-radius: 18px;" class="py-1 px-5" min-height="100%"> 
      <div :class="['text-center',title_class]"><v-icon size="16" :color="icon_color">{{icon}}</v-icon> {{title}}</div>
      <div class="headline mt-0 font-weight-black text-center">{{formatNumber(value)}}</div>
      <slot></slot>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'TotalRegistered',

    props: {
      value: {
        type: Number,
      },
      title: {
        type: String,
      },
      icon: {
        type: String,
      },
      title_class:{
        type: String,
      },
      icon_color:{
        type: String,
      }
      
    },

    methods: {
      formatNumber(entry){
        return Number(entry).toLocaleString('pt-BR')
      }
    },
    
  }
</script>

<style lang="scss" scoped>

</style>